<template>
  <Card :class="participant.id ? '' : 'is-disabled'">
    <template #header>
      <div class="flex f-jsb f-ai">
        <p>
          {{ participant.participant_full_name || $tc('participant.label', 1) }}
        </p>
        <PhoneViewer
          class="headerPhoneViewer"
          :phone="participantPhone.mobile || participantPhone.land"
          justIcon
        />
      </div>
    </template>
    <div>
      <Deliveries :isOpen.sync="showDeliveryModal" :participant="participant" isFromParticipants />
      <div class="profile flex">
        <MainCardPicture
          :id="participant.id"
          :src="participant.pictureSrc"
          api="participant"
          :alt="participant.participant_full_name"
        />
        <div class="details">
          <h2>{{ $t('participant.infoLabel') }}</h2>
          <div class="flex f-jsb mb-4">
            <Card-item
              :title="$t('participant.dob')"
              :value="birthday(participant.participant_date_of_birth)"
            />
            <Card-item
              :title="$tc('global.gender.label', 0)"
              :value="participant.participant_gender || '-'"
            />
            <Card-item :title="$t('global.ST.label')" :value="participant.participant_space_type" />
          </div>
          <div class="mb-4">
            <Card-item
              class="mb-4"
              :title="$t('address.address', [''])"
              :value="participantAddress.address || '-'"
            />
            <Card-item
              :title="$t('address.address', [1])"
              :value="participantAddress.address_1 || '-'"
            />
          </div>
          <div class="flex mb-4">
            <Card-item :title="$t('contact.phone')">
              <Phone-viewer :phone="participantPhone.land" />
            </Card-item>
            <Card-item :title="$t('contact.mobile')">
              <Phone-viewer :phone="participantPhone.mobile" />
            </Card-item>
          </div>
          <div class="flex mb-4">
            <Card-item :title="$t('address.city')" :value="participantAddress.city || '-'" />
            <Card-item :title="$t('address.state', [2])" :value="participantAddress.state || '-'" />
            <Card-item :title="$t('address.zp', [2])" :value="participantAddress.zipcode || '-'" />
            <Link
              v-if="participantAddress.full_address"
              :label="$t('button.showMap')"
              icon="map-marker"
              @click="onShowPosition"
            />
          </div>
          <Card-item
            class="mb-4"
            :title="$t('global.fundingSrc')"
            :value="participant.funding_source_name || '-'"
            expanded
          />
          <Card-item
            :title="$t('participant.pcom')"
            :value="participant.participant_permanent_comment || '-'"
            expanded
          />
        </div>
      </div>
      <Conditions-needs :participant="participant" />
    </div>
    <PastServices :participant="participant" />
    <template #footer>
      <div class="flex f-full f-ai f-jsb">
        <My-switch
          :value="participant.participant_is_active"
          :text="$tc('switch.active', participant.participant_is_active ? 1 : 2)"
          @input="toggleActive"
          :disabled="!participant.id || loading.toggleActive"
          :loading="loading.toggleActive"
          :permission="Permission.toggle"
        />
        <div class="buttons">
          <Button
            type="is-danger"
            @click="onDelete"
            :label="$t('button.delete')"
            :disabled="!participant.id"
            :loading="loading.deleteParticipant"
            :permission="Permission.delete"
            outlined
          />
          <Button
            type="is-primary"
            @click="onEdit"
            :label="$t('button.edit')"
            :disabled="!participant.id"
            :permission="Permission.update"
          />
          <Button
            type="is-primary"
            :label="$t('schedule.newUnassignedDelivery')"
            @click="showDeliveryModal = true"
            :disabled="isDisabled || !participant.id"
            :permission="SchedulingPermission.create"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
// import { send_sms } from '@/utils/ringCentral';
// import { toast } from '@/utils/dialog';
import ConditionsNeeds from '@/components/Participants/ConditionsNeeds.vue';
import { Deliveries } from '@/views/Fragments';
import MySwitch from '@/components/iSwitch.vue';
import PastServices from './PastServices.vue';
import { Button, Card, CardItem, PhoneViewer, Link, MainCardPicture } from '@/components';
import { Permissions } from '@/utils/Secure';
import { confirm } from '@/utils/dialog';

export default {
  components: {
    Button,
    Card,
    CardItem,
    ConditionsNeeds,
    Deliveries,
    Link,
    MainCardPicture,
    MySwitch,
    PastServices,
    PhoneViewer
  },
  data() {
    return {
      data: {},
      isDisabled: !this.participant.participant_is_active,
      loading: { toggleActive: false, deleteParticipant: false },
      showDeliveryModal: false
    };
  },
  computed: {
    participantAddress() {
      const { participant_address } = this.participant;
      if (typeof participant_address === 'string') {
        return {
          address: this.participant.participant_address,
          address_1: this.participant.participant_address_1,
          city: this.participant.participant_address_city,
          state: this.participant.participant_address_state,
          zipcode: this.participant.participant_address_zipcode,
          full_address: this.participant.participant_address_full_address
        };
      } else {
        const defaultAddress = participant_address?.find((pa) => pa.is_default);
        if (!defaultAddress) return {};
        const { latitude: lat, longitude: lng, ...PA } = defaultAddress;
        return { ...PA, latLng: { lat, lng } } || {};
      }
    },
    Permission() {
      return Permissions.Participants;
    },
    SchedulingPermission() {
      return Permissions.Scheduling;
    },
    participantPhone() {
      let land = '';
      const { participant_mobile_phone_number: mobile } = this.participant;
      const { participant_phone_number } = this.participant;
      if (typeof participant_phone_number === 'string') land = participant_phone_number;
      return {
        mobile: mobile || this.filterDefaultPhone('mobile')?.phone_number,
        land: land || this.filterDefaultPhone('land')?.phone_number
      };
    }
  },
  watch: {
    participant() {
      this.isDisabled = !this.participant.participant_is_active;
    }
  },
  methods: {
    filterDefaultPhone(type) {
      const { participant_phone_number } = this.participant;
      return participant_phone_number
        ?.filter((P) => P.number_type === type)
        ?.find((P) => P.is_default);
    },
    birthday(date) {
      const birthday = this.$moment(date);
      const birthdayStr = birthday.format('MM/DD/Y');
      const age = this.$moment().diff(birthday, 'years');
      return date ? `${birthdayStr} ${age} ${this.$i18n.t('yearsOld')}` : '-';
    },
    onEdit() {
      this.$router.push({ path: `/data/participants/${this.participant.id}` });
    },
    onDelete() {
      const message = `<strong>${this.$t('participant.participant')}:</strong> ${
        this.participant.participant_full_name
      }`;
      confirm({
        title: this.$t('participant.delete'),
        message,
        cancelText: this.$t('confirm.no'),
        confirmText: this.$t('button.delete'),
        onConfirm: async () => {
          this.onDeleteConfirm();
        }
      });
    },
    async onDeleteConfirm(isOverride = false) {
      const { id } = this.participant;
      this.loading.deleteParticipant = true;
      try {
        await this.Api.delete(`/participants/${id}${isOverride ? '/override' : ''}`);
        this.$emit('onDelete', id);
        this.$toast('success', this.$t('participant.deleted'), 5000);
      } catch (error) {
        if (error?.status === 400) {
          const message = `<strong>${this.$t('participant.participant')}:</strong> ${
            this.participant.participant_full_name
          }`;
          confirm({
            title: this.$t('participant.deleteOverride'),
            message,
            cancelText: this.$t('confirm.no'),
            confirmText: this.$t('button.delete'),
            onConfirm: async () => {
              this.onDeleteConfirm(true);
            }
          });
        }
        console.log(error);
      }
      this.loading.deleteParticipant = false;
    },
    onShowPosition() {
      const data = this.participantAddress;
      this.$emit('showPosition', data);
      if (!this.$listeners.showPosition) {
        this.$store.commit('map/breadcumbs', [
          'participant.participant',
          this.participant.participant_full_name
        ]);
        this.$store.dispatch('map/updateMapWindow', {
          action: 'showPosition',
          data
        });
      }
    },
    async toggleActive(is_active, isOverride) {
      this.loading.toggleActive = true;
      try {
        await this.Api.patch(
          `participants/${this.participant.id}/is_active/toggle${isOverride ? '/override' : ''}`
        );
        this.participant.participant_is_active = is_active;
        this.isDisabled = !is_active;
      } catch (error) {
        if (error) {
          return await new Promise((resolve) => {
            confirm({
              title: this.$t('participant.activeOverride'),
              cancelText: this.$t('confirm.no'),
              confirmText: this.$t('confirm.yes'),
              onCancel: () => {
                const value = this.participant.participant_is_active;
                this.participant.participant_is_active = !value;
                setTimeout(() => {
                  this.participant.participant_is_active = value;
                }, 100);
                this.loading.toggleActive = false;
                resolve(false);
              },
              onConfirm: () => this.toggleActive(is_active, true)
            });
          });
        }
      }
      this.loading.toggleActive = false;
    }
  },
  props: {
    participant: { type: Object, default: () => ({}), required: true }
  }
};
</script>

<style lang="sass" scoped>
.card
  height: 100%
  width: 100%
  margin: 0
  ::v-deep .card-content
    display: flex
    flex-flow: column
    justify-content: space-between
.headerPhoneViewer
  width: 25px
.profile
  .details
    width: 100%
    h2
      font-size: 18px
      font-weight: bold
      margin-bottom: 15px
.conditionNeeds
  justify-content: flex-end
  padding: 3px 0
  margin-bottom: 10px
.iSwitch
  margin: 0
.buttons
  justify-content: space-between
  margin-left: auto
</style>
